/* eslint-disable react/function-component-definition */
/* eslint-disable no-irregular-whitespace */
import Link from "next/link";
import Image from "next/image";
import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { footerNavigation } from "../../data";

const Footer = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const smallScreenFooter = (
    <footer aria-labelledby="footer-heading" className="bg-gray-50">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-6">
        <div className="flex flex-col items-start">
          <div className="flex flex-col items-start mt-4">
            <h3 className="text-md font-medium text-black">Navigation</h3>
            <ul className="space-y-1">
              {footerNavigation.navigation.map((item) => (
                <li key={item.name} className="text-sm">
                  <Link
                    href={item.href}
                    className="text-gray-500 hover:text-gray-600"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col items-start mt-4">
            <h3 className="text-md font-medium text-black">Support</h3>
            <ul className="space-y-1">
              {footerNavigation.customerService.map((item) => (
                <li key={item.name} className="text-sm">
                  <Link
                    href={item.href}
                    className="text-gray-500 hover:text-gray-600"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col items-start mt-4">
            <h3 className="text-md font-medium text-black">Company</h3>
            <ul className="space-y-1">
              {footerNavigation.company.map((item) => (
                <li key={item.name} className="text-sm">
                  <Link
                    href={item.href}
                    className="text-gray-500 hover:text-gray-600"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col items-start mt-4">
            <h3 className="text-md font-medium text-black">Legal</h3>
            <ul className="space-y-1">
              {footerNavigation.legal.map((item) => (
                <li key={item.name} className="text-sm">
                  <Link
                    href={item.href}
                    className="text-gray-500 hover:text-gray-600"
                  >
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="py-10 md:flex md:items-center md:justify-between">
          <div className="flex flex-col text-center md:text-left mx-auto">
            <ul className="flex flex-row justify-center items-center my-2">
              {footerNavigation.social.map((item) => (
                <li key={item.name} className="text-sm">
                  <Link
                    href={item.href}
                    className="text-gray-500 hover:text-gray-600"
                  >
                    <Image
                      className="w-auto h-8 px-2 py-1"
                      height={50}
                      width={50}
                      src={item.image}
                      alt={item.name}
                    />
                  </Link>
                </li>
              ))}
            </ul>
            <p className="text-sm text-gray-500">
              &copy; {new Date().getFullYear()} All Rights Reserved
            </p>
          </div>
        </div>
      </div>
    </footer>
  );

  const largeScreenFooter = (
    <>
      <footer aria-labelledby="footer-heading" className="bg-gray-50">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="px-16">
          <div className="mt-4 grid grid-cols-4 gap-8 mx-autopx-2 px-2">
            <div className="flex flex-col items-start">
              <h3 className="text-md font-medium text-black mt-2">
                Navigation
              </h3>
              <ul className="mt-6 space-y-2">
                {footerNavigation.navigation.map((item) => (
                  <li key={item.name} className="text-sm">
                    <Link
                      href={item.href}
                      className="text-gray-500 hover:text-gray-600"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex flex-col items-start">
              <h3 className="text-md font-medium text-black mt-2">Support</h3>
              <ul className="mt-6 space-y-2">
                {footerNavigation.customerService.map((item) => (
                  <li key={item.name} className="text-sm">
                    <Link
                      href={item.href}
                      className="text-gray-500 hover:text-gray-600"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex flex-col items-start">
              <h3 className="text-md font-medium text-black mt-2">Company</h3>
              <ul className="mt-6 space-y-2">
                {footerNavigation.company.map((item) => (
                  <li key={item.name} className="text-sm">
                    <Link
                      href={item.href}
                      className="text-gray-500 hover:text-gray-600"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="flex flex-col items-start">
              <h3 className="text-md font-medium text-black mt-2">Legal</h3>
              <ul className="mt-6 space-y-2">
                {footerNavigation.legal.map((item) => (
                  <li key={item.name} className="text-sm">
                    <Link
                      href={item.href}
                      className="text-gray-500 hover:text-gray-600"
                    >
                      {item.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="py-10 md:flex md:items-center md:justify-between">
            <div className="flex flex-col text-center md:text-left mx-auto">
              <ul className="flex flex-row justify-center items-center my-2">
                {footerNavigation.social.map((item) => (
                  <li key={item.name} className="text-sm">
                    <Link
                      href={item.href}
                      className="text-gray-500 hover:text-gray-600"
                      target="_blank"
                    >
                      <Image
                        className="w-auto h-8 px-2 py-1"
                        height={50}
                        width={50}
                        src={item.image}
                        alt={item.name}
                      />
                    </Link>
                  </li>
                ))}
              </ul>
              <p className="text-sm text-gray-500 text-center">
                &copy; {new Date().getFullYear()} All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );

  return <>{isSmallScreen ? smallScreenFooter : largeScreenFooter}</>;
};

export default Footer;
