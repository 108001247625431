import React, { Fragment, useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  XMarkIcon,
  UsersIcon,
  BoltIcon,
} from "@heroicons/react/24/outline";
import { useRouter } from "next/router";
import Link from "next/link";
import Image from "next/image";
import localforage from "localforage";
import { fetchAuthSession } from "aws-amplify/auth";
import useSWR from "swr";
import CartComponent from "../cart/Cart";
import CartIconComponent from "../cart/CartIcon";
import { fetchPostJSON } from "../../utils/api-helpers";
import { NAV_ICON } from "../../data";
import { RemainingApiResponseData } from "../../src/types";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

// Main function for the navbar
export default function Navbar() {
  const router = useRouter();
  const { signOut, authStatus, user } = useAuthenticator((context) => [
    context.authStatus,
    context.user,
  ]);

  // Navigation options
  const navigation = [
    { name: "About", href: "/about", current: false },
    { name: "Discover", href: "/discover", current: false },
    ...(authStatus === "authenticated"
      ? [{ name: "Gallery", href: "/gallery", current: false }]
      : []),
    { name: "Create", href: "/create-story", current: false },
    { name: "Gift", href: "/gifts", current: false },
  ];

  const { data: remainingResponse } = useSWR<RemainingApiResponseData>(
    "/api/remaining",
    () =>
      fetchPostJSON("/api/remaining", {
        id: user.userId,
      }),
  );

  const [credits, setCredits] = useState<number>();
  const [userGroups, setUserGroups] = useState<string[]>([]);

  useEffect(() => {
    setCredits(remainingResponse?.remainingGenerations);
  }, [remainingResponse]);

  async function logOut() {
    try {
      // Attempt to sign the user out
      const logout = await signOut({ global: true });
      console.log(logout);
    } catch (error) {
      console.error("Error signing out: ", error);
    }

    try {
      // Attempt to clear local storage
      await localforage.clear();
      // console.log("Local storage cleared successfully.");

      // After clearing local storage, redirect to the login page
      router.push("/login");
    } catch (err) {
      console.error("Error clearing local storage:", err);
    }
  }

  // Define the image source based on the current route
  const imageSrc = router.pathname === "/" ? NAV_ICON : NAV_ICON;

  // Define the Tailwind CSS classes based on imageSrc and route
  const imageClasses =
    router.pathname === "/"
      ? "h-8 w-28 object-contain" // Classes for the root page
      : "h-8 w-28 object-contain"; // Classes for other pages

  // Define width and height based on imageSrc
  const imageWidth = imageSrc ? 50 : 200;
  const imageHeight = imageSrc ? 50 : 50;

  useEffect(() => {
    async function getUserGroups() {
      const { accessToken } = (await fetchAuthSession()).tokens ?? {};
      // const authUser = await Auth.currentAuthenticatedUser();
      setUserGroups(accessToken.payload["cognito:groups"] as string[]);
    }
    if (authStatus === "authenticated" && user) {
      getUserGroups();
    }
    // subscribe();
  }, [authStatus, user]);

  const admin = userGroups?.includes("admin");

  const [showAnnouncementBar, setShowAnnouncementBar] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.pathname === "/") {
        setShowAnnouncementBar(true);
      } else {
        setShowAnnouncementBar(false);
      }
    }
  }, [typeof window !== "undefined" ? window.location.pathname : null]);

  const [showAdminBar, setShowAdminBar] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined") {
      if (window.location.pathname.startsWith("/admin")) {
        setShowAdminBar(true);
      } else {
        setShowAdminBar(false);
      }
    }
  }, [typeof window !== "undefined" ? window.location.pathname : null]);

  return (
    <>
      <CartComponent />
      <Disclosure as="nav" className="bg-white">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-full">
              {showAnnouncementBar && (
                <div
                  className="text-white py-2 text-center bg-amber-200"
                  style={{ backgroundColor: "#d29668" }}
                >
                  <p className="font-bold text-xs">
                    Free Shipping & 15% Off Your First Order Using Code LAUNCH15
                  </p>
                </div>
              )}
              {showAdminBar && (
                <div
                  className="text-white py-2 text-center bg-amber-200"
                  style={{ backgroundColor: "#FF0000" }}
                >
                  <p className="font-bold text-sm">Admin Mode</p>
                </div>
              )}
              <div className="relative flex h-16 items-center justify-between">
                <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center pl-2">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                  <Link
                    href="/"
                    as="/"
                    className="no-underline flex flex-shrink-0 items-center"
                  >
                    <Image
                      className={imageClasses}
                      priority
                      src={imageSrc}
                      alt="Raraity"
                      width={imageWidth}
                      height={imageHeight}
                    />
                  </Link>
                </div>
                <div className="flex items-center justify-center md:items-stretch md:justify-start">
                  <div className="hidden md:ml-6 md:block">
                    <div className="flex space-x-10 items-center">
                      <Link
                        href="/"
                        className="no-underline py-2 flex flex-shrink-0 items-center"
                      >
                        <Image
                          className={imageClasses}
                          src={imageSrc}
                          alt="Raraity"
                          width={imageWidth}
                          height={imageHeight}
                        />
                      </Link>
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? "no-underline py-2 text-2xl"
                              : "text-gray-700 hover:text-black no-underline text-xl",
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end items-center">
                  {authStatus !== "authenticated" && (
                    <Link
                      className="p-2 text-gray-700 hover:bg-gray-300 rounded"
                      href="/login"
                    >
                      {" "}
                      Login &nbsp;| &nbsp;Sign Up{" "}
                    </Link>
                  )}
                  {credits <= 10 && (
                    <Menu as="div" className="relative">
                      <Menu.Button className="bg-white p-2 text-gray-700 hover:text-black">
                        <span className="sr-only">Open credit menu</span>
                        <BoltIcon
                          className="h-8 w-8 rounded-full p-1"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right bg-white text-gray-700 shadow-lg p-1 rounded">
                          {authStatus === "authenticated" ? (
                            <>
                              <Menu.Item>
                                <span className="block items-center p-1 hover:bg-gray-300 rounded">
                                  Current Credits:&nbsp;
                                  {credits}
                                </span>
                              </Menu.Item>
                              <Menu.Item>
                                <Link
                                  href="/credits"
                                  className="block items-center p-1 hover:bg-gray-300 rounded"
                                >
                                  Buy Credits
                                </Link>
                              </Menu.Item>
                            </>
                          ) : (
                            <>
                              <Menu.Item>
                                <Link
                                  href="/login"
                                  className="block items-center p-1 hover:bg-gray-300 rounded"
                                >
                                  Buy Credits
                                </Link>
                              </Menu.Item>
                            </>
                          )}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}
                  {/* Account dropdown */}
                  <Menu as="div" className="relative">
                    <Menu.Button className="bg-white p-2 text-gray-700 hover:text-black">
                      <span className="sr-only">Open user menu</span>
                      <UsersIcon
                        className="h-8 w-8 rounded-full p-1"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 w-36 p-1 origin-top-right bg-white text-gray-700 shadow-lg rounded border">
                        <Menu.Item>
                          {authStatus !== "authenticated" ? (
                            <Link
                              href="/login"
                              className="block items-center p-1 hover:bg-gray-300 rounded"
                            >
                              Account
                            </Link>
                          ) : (
                            <Link
                              href="/account"
                              className="block items-center p-1 hover:bg-gray-300 rounded"
                            >
                              Account
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {authStatus !== "authenticated" ? (
                            <Link
                              href="/login"
                              className="block items-center p-1 hover:bg-gray-300 rounded"
                            >
                              Orders
                            </Link>
                          ) : (
                            <Link
                              href="/orders"
                              className="block items-center p-1 hover:bg-gray-300 rounded"
                            >
                              Orders
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {authStatus !== "authenticated" ? (
                            <Link
                              href="/login"
                              className="block items-center p-1 hover:bg-gray-300 rounded"
                            >
                              Gallery
                            </Link>
                          ) : (
                            <Link
                              href="/gallery"
                              className="block justify-center items-center p-1 hover:bg-gray-300 rounded"
                            >
                              Gallery
                            </Link>
                          )}
                        </Menu.Item>
                        {authStatus === "authenticated" && admin && (
                          <>
                            <Menu.Item>
                              <Link
                                href="/admin/discover"
                                className="block items-center p-1 hover:bg-gray-300 rounded"
                              >
                                Admin Discover
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link
                                href="/admin/create-story"
                                className="block items-center p-1 hover:bg-gray-300 rounded"
                              >
                                Admin Create
                              </Link>
                            </Menu.Item>
                          </>
                        )}
                        <Menu.Item>
                          {authStatus !== "authenticated" ? (
                            <Link
                              href="/login"
                              className="w-full block text-left p-1 hover:bg-gray-300 rounded"
                            >
                              Sign In
                            </Link>
                          ) : (
                            <button
                              type="button"
                              className="w-full block text-left p-1 hover:bg-gray-300 rounded"
                              onClick={() => logOut()}
                            >
                              Sign Out
                            </button>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                  <CartIconComponent />
                </div>
              </div>
            </div>
            <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pb-3 pt-2">
                {navigation.map((item) => (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className="block items-center text-lg text-gray-700 p-1 hover:bg-gray-300 rounded"
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
