/* eslint-disable no-restricted-globals */
/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from "react";
import { useRouter } from "next/router";
import localforage from "localforage";
import { fetchUserAttributes } from "aws-amplify/auth";
import { PlusIcon } from "@heroicons/react/24/solid";
import Link from "next/link";
import { Divider } from "@aws-amplify/ui-react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import getStripe from "../../utils/get-stripejs";
import { cartStateStore } from "../../utils/store";
import { CartContentType, CheckoutCartType } from "../../src/types";
import CartArtImageComponent from "./CartImages";
import { fetchPostJSON } from "../../utils/api-helpers";

// eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
function TaxModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <p className="text-xs text-gray-500">
          Raraity is required by state and local laws to charge applicable sales
          tax for online orders. Please note that during checkout relects the
          estimated sales tax. The actual charge to your credit card will
          include the applicable state and local sales tax.
        </p>
      </div>
    </div>
  );
}

function CartItemsComponent() {
  const router = useRouter();
  const open = cartStateStore((state) => state.open);
  const setOpen = cartStateStore((state) => state.setOpen);
  const [arts, setArt] = useState<any>(null);
  const [removeCartItem, setRemoveCartItem] = useState(false);
  const [taxOpen, setTaxOpen] = useState(false);
  const selectedTaxRef = useRef(null);

  const sum = arts?.reduce((a: any, b: any) => a + b.total, 0);
  const totalPrice: number =
    typeof sum === "number" ? Number(sum.toFixed(2)) : 0;

  async function fetchArtOrderID() {
    try {
      const foundValue = await new Promise((resolve, reject) => {
        localforage
          .iterate((value: CartContentType) => {
            if (value && typeof value === "object" && "artOrderID" in value) {
              resolve(value.artOrderID); // Resolve the Promise with the found cartID
            }
          })
          .then(() => resolve(null)) // Resolve with null if no item with cartID was found
          .catch(reject);
      });

      if (foundValue !== null) {
        return foundValue;
      }
      return null;
    } catch (err) {
      console.error(err);
      return null;
    }
  }

  const removeArt = async (id: string) => {
    try {
      const artOrderId = await fetchArtOrderID();
      // console.log(artOrderId);
      // Submit the body to the API
      const res = await fetch("/api/removefromcart", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          artOrderId,
        }),
      });
      await res.json();
      // console.log(response);
      localforage.removeItem(id);
      setRemoveCartItem(!removeCartItem);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    const fetchCartContent = async () => {
      try {
        const data: any = [];
        await localforage.iterate((value: CartContentType) => {
          data.push({ ...value });
        });
        setArt(data);
        // console.log("printing arts ", data);
      } catch (err) {
        console.log(err);
      }
    };
    fetchCartContent();
  }, [open, removeCartItem]);

  const handleCheckout = async () => {
    const userAttributes = await fetchUserAttributes();
    if (!userAttributes.email || userAttributes.email.trim() === "") {
      throw new Error("Invalid email address");
    }

    // Call the checkout session
    try {
      const response = await fetchPostJSON("/api/checkout_sessions/cart", {
        arts: arts as CheckoutCartType,
      });

      // Wait for the response
      if (response.statusCode === 500) {
        console.error(response.message);
        return;
      }

      // Redirect to Checkout.
      const stripe = await getStripe();
      await stripe!.redirectToCheckout({
        sessionId: response.id,
      });
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      } else {
        console.error(error);
      }
    }
  };

  function currencyFormat(num) {
    // Ensure num is a number before using toFixed
    const numberValue = parseFloat(num);
    if (isNaN(numberValue)) {
      // Handle the case where num is not a valid number
      return "Invalid Number";
    }
    return numberValue.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  // Add event listeners to handle clicks outside of popups
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        selectedTaxRef.current &&
        !selectedTaxRef.current.contains(event.target)
      ) {
        // Click occurred outside of frame options popup
        setTaxOpen(false);
      }
    };
    // Add the event listener to the document
    document.addEventListener("mousedown", handleClickOutside);
    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleTaxModal = () => {
    setTaxOpen((prevTaxOpen) => !prevTaxOpen);
  };

  return (
    <>
      {arts && arts.length > 0 ? (
        <>
          {arts?.map((art: CartContentType, i: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <li key={i} className="flex py-6">
              <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                <CartArtImageComponent
                  src={art.artId}
                  alt={`${art.title} image`}
                />
              </div>
              <div className="ml-4 flex flex-1 flex-col">
                <div>
                  <div className="flex justify-between font-medium text-gray-900">
                    <h3>Raraity Generated Art</h3>
                    <p className="ml-4">${currencyFormat(art.total)}</p>
                  </div>
                </div>
                <div>
                  <div className="flex justify-between text-gray-500">
                    <h5 className="text-xs">
                      Frame Color: {art.moulding_finish}
                    </h5>
                  </div>
                  <div className="flex justify-between text-gray-500">
                    <h5 className="text-xs">
                      Size: {art.length}'H x {art.width}'W
                    </h5>
                  </div>
                  <div className="flex justify-between text-gray-500">
                    <h5 className="text-xs">Mount: {art.mount}</h5>
                  </div>
                  <div className="flex justify-between text-gray-500">
                    <h5 className="text-xs">Glass: {art.plexiglass}</h5>
                  </div>
                </div>
                <div className="flex flex-1 items-end justify-end text-sm">
                  <button
                    type="button"
                    onClick={() => removeArt(art.artId)}
                    className="font-medium text-gray-500 hover:text-gray-500"
                  >
                    Remove
                  </button>
                </div>
              </div>
            </li>
          ))}

          <div className="border-t border-gray-200 py-6 px-4 sm:px-6">
            <div className="flex justify-between">
              <p className="mt-0.5 text-sm text-gray-500">Subtotal</p>
              <p className="mt-0.5 text-sm text-gray-500">
                ${currencyFormat(totalPrice)}
              </p>
            </div>
            <div className="flex justify-between">
              <p className="mt-0.5 text-sm text-gray-500">
                Shipping & Handling
              </p>
              <p className="mt-0.5 text-sm text-gray-500">FREE</p>
            </div>
            <div className="flex justify-between">
              <p className="mt-0.5 text-sm text-gray-500">
                Estimated Tax &nbsp;&nbsp;
                <button
                  type="button"
                  onClick={toggleTaxModal}
                  ref={selectedTaxRef}
                >
                  <InfoOutlinedIcon fontSize="small" />
                </button>
              </p>
              <p className="mt-0.5 text-sm text-gray-500">TBD</p>
            </div>
            <TaxModal isOpen={taxOpen} onClose={() => setTaxOpen(false)} />
            <Divider padding="xs" />
            <div className="mt-0.5 flex justify-between">
              <p className="mt-0.5 text-sm font-medium text-black">
                Estimated Total
              </p>
              <p className="mt-0.5 text-sm font-medium text-black">
                ${currencyFormat(totalPrice)}
              </p>
            </div>
            <div className="mt-6 flex justify-center">
              <button
                type="button"
                onClick={() => {
                  setOpen(!open);
                  handleCheckout();
                }}
                className="bg-black text-white py-3 w-full rounded-full mt-4 hover:bg-gray-800 focus:outline-none active:bg-gray-800"
              >
                Checkout
              </button>
            </div>
            <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
              <p>
                or{" "}
                <button
                  type="button"
                  className="font-medium text-gray-600 hover:text-gray-500"
                  onClick={() => {
                    setOpen(false);
                    router.push("/gallery");
                  }}
                >
                  Continue Shopping
                  <span aria-hidden="true"> &rarr;</span>
                </button>
              </p>
            </div>
          </div>
        </>
      ) : (
        <div className="text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">No Art</h3>
          <div className="mt-6">
            <Link href="/create">
              <div
                onClick={() => {
                  setOpen(!open);
                }}
                aria-hidden="true"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                Create Art
              </div>
            </Link>
          </div>
        </div>
      )}
    </>
  );
}

export default CartItemsComponent;
